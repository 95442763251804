define('ember-autosave/computed-autosave', ['exports', 'ember-autosave/autosave-proxy'], function (exports, _autosaveProxy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = computedAutosave;
  function computedAutosave() {
    let propertyName, options;

    if (typeof arguments[0] === 'string') {
      [propertyName, options = {}] = arguments;
    } else if (typeof arguments[0] === 'object') {
      options = arguments[0];
    } else {
      throw new Error('Invalid arguments passed to computedAutosave: ', arguments);
    }

    let computedArgs = {
      get: function () {
        options.context = this;

        let model;
        if (propertyName) {
          model = Ember.get(this, propertyName);
        }

        return _autosaveProxy.default.create(model, options);
      }
    };

    if (propertyName) {
      return Ember.computed(propertyName, computedArgs);
    } else {
      return Ember.computed(computedArgs);
    }
  }
});
define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [["en-us", {
    "price_banner": "The {product} costs {price, number, ::currency/USD}"
  }]];
});